import { Checkbox, FormControlLabel, FormControlLabelProps, styled } from "@mui/material";

interface CheckboxFieldProps extends Omit<FormControlLabelProps, "control"> {
  checked: boolean;
  name: string;
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "checked",
})<CheckboxFieldProps>(({ theme, checked }) => ({
  ...(theme.components?.CheckboxField?.styleOverrides?.root?.({
    ownerState: { checked },
    theme,
  }) || {}),
}));

export const CheckboxField = ({ checked, onChange, name, label, ...props }: CheckboxFieldProps): JSX.Element => {
  return (
    <StyledFormControlLabel
      control={<Checkbox checked={checked} name={name} sx={{ p: 0 }} />}
      label={label}
      checked={checked}
      name={name}
      onChange={onChange}
      {...props}
    />
  );
};
