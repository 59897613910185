import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IndexType } from "@/types";
import { PageType } from "@/types/pageTypes";

// Define a type for the slice state
export interface ApplicationMainSliceState {
  currentPage: PageType;
  selectedIndex?: IndexType;
  authenticated?: boolean;
}

// Define the initial state using that type
const initialState: ApplicationMainSliceState = {
  currentPage: PageType.Login,
  selectedIndex: undefined,
  authenticated: false,
};

// Implement the slice
export const applicationMainSlice = createSlice({
  name: "applicationMain",
  // 'createSlice' will infer the state type from the 'initialState' argument
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<PageType>) => {
      state.currentPage = action.payload;
    },
    setSelectedIndex: (state, action: PayloadAction<IndexType>) => {
      state.selectedIndex = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
    },
  },
});

export const { setCurrentPage, setSelectedIndex, setAuthenticated } = applicationMainSlice.actions;

export const PagesReducer = applicationMainSlice.reducer;
