import { getDefaultFilterForIndex } from "@/helpers/playgroundFilterHelper";
import { PlaygroundFilter } from "@/types/playground";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface PlaygroundPageSliceState {
  filter: PlaygroundFilter
}

// Define the initial state using that type
const initialState: PlaygroundPageSliceState = {
  filter: getDefaultFilterForIndex()
};

// Implement the slice
export const playgroundPageSlice = createSlice({
  name: "playgroundPage",
  // 'createSlice' will infer the state type from the 'initialState' argument
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PlaygroundFilter>) => {
      state.filter = action.payload;
    }
  }
});

export const { setFilter } = playgroundPageSlice.actions;

export const PlaygroundPageReducer = playgroundPageSlice.reducer;
