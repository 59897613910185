import { Box, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RadioField } from "@/components";
import { CompareType } from "@/types/playground";

import { PlaygroundFilterSectionTitle } from "./PlaygroundFilterSectionTitle";

const compareTypes = Object.values(CompareType);

export type CompareTypeSectionProps = {
  currentCompareType?: CompareType;
  onCurrentCompareTypeChange: (compareType: CompareType) => void;
};

export const CompareTypeSection = ({
  currentCompareType,
  onCurrentCompareTypeChange,
}: CompareTypeSectionProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");

  const onCompareTypeChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    onCurrentCompareTypeChange(value as CompareType);
  };

  return (
    <Box sx={{ p: "13px 20px" }}>
      <PlaygroundFilterSectionTitle title={t("filter.compareTypeSection.title")} />
      <RadioGroup value={currentCompareType} onChange={onCompareTypeChange}>
        {compareTypes.map((compareType, index) => (
          <Box
            key={compareType}
            sx={{
              ...(index < compareTypes.length - 1 && { mb: 1 }),
            }}
          >
            <RadioField
              label={t("filter.compareTypeSection.options", { context: compareType })}
              value={compareType}
              selected={currentCompareType === compareType}
            />
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};
