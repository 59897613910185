import { IndexValue, IndexValueChangeType } from "@/types";
import { DateTime } from "luxon";

export const demoIndexValues: IndexValue[] = [
  {
    id: 0,
    postalCode: "4076",
    city: "Düsseldorf",
    objectType: "MFH",
    indexValue: 4.5,
    indexName: "S-IM",
    indexValueDate: DateTime.fromJSDate(new Date(2024, 2, 1)),
    oneYearChange: 1,
    oneYearChangeType: IndexValueChangeType.Increase,
    threeYearChange: -3,
    threeYearChangeType: IndexValueChangeType.Decrease,
  },
  {
    id: 1,
    postalCode: "4076",
    city: "Düsseldorf",
    objectType: "EFH",
    indexValue: 4.2,
    indexName: "S-IM",
    indexValueDate: DateTime.fromJSDate(new Date(2024, 2, 1)),
    oneYearChange: 2,
    oneYearChangeType: IndexValueChangeType.Increase,
    threeYearChange: -2,
    threeYearChangeType: IndexValueChangeType.Decrease,
  },
  {
    id: 2,
    postalCode: "4076",
    city: "Düsseldorf",
    objectType: "MFH",
    indexValue: 4.5,
    indexName: "S-IM",
    indexValueDate: DateTime.fromJSDate(new Date(2024, 2, 1)),
    oneYearChange: 1,
    oneYearChangeType: IndexValueChangeType.Increase,
    threeYearChange: -3,
    threeYearChangeType: IndexValueChangeType.Decrease,
  },
  {
    id: 3,
    postalCode: "4076",
    city: "Düsseldorf",
    objectType: "EFH",
    indexValue: 4.2,
    indexName: "S-IM",
    indexValueDate: DateTime.fromJSDate(new Date(2024, 2, 1)),
    oneYearChange: 2,
    oneYearChangeType: IndexValueChangeType.Increase,
    threeYearChange: -2,
    threeYearChangeType: IndexValueChangeType.Decrease,
  },
  {
    id: 4,
    postalCode: "4076",
    city: "Düsseldorf",
    objectType: "MFH",
    indexValue: 4.5,
    indexName: "S-IM",
    indexValueDate: DateTime.fromJSDate(new Date(2024, 2, 1)),
    oneYearChange: 1,
    oneYearChangeType: IndexValueChangeType.Increase,
    threeYearChange: -3,
    threeYearChangeType: IndexValueChangeType.Decrease,
  },
  {
    id: 5,
    postalCode: "4076",
    city: "Düsseldorf",
    objectType: "EFH",
    indexValue: 4.2,
    indexName: "S-IM",
    indexValueDate: DateTime.fromJSDate(new Date(2024, 2, 1)),
    oneYearChange: 2,
    oneYearChangeType: IndexValueChangeType.Increase,
    threeYearChange: -2,
    threeYearChangeType: IndexValueChangeType.Decrease,
  },
];
