import { FormControlLabel, FormControlLabelProps, Radio, styled } from "@mui/material";

interface RadioFieldProps extends Omit<FormControlLabelProps, "control"> {
  selected: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "selected",
})<RadioFieldProps>(({ theme, selected }) => ({
  ...(theme.components?.RadioField?.styleOverrides?.root?.({
    ownerState: { selected },
    theme,
  }) || {}),
}));

export const RadioField = ({ selected, value, label, ...props }: RadioFieldProps): JSX.Element => {
  return (
    <StyledFormControlLabel
      control={<Radio sx={{ p: 0 }} />}
      label={label}
      selected={selected}
      value={value}
      {...props}
    />
  );
};
