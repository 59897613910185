import { Box, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RadioField } from "@/components";
import { TimeResolution } from "@/types/playground";

import { PlaygroundFilterSectionTitle } from "./PlaygroundFilterSectionTitle";

const timeResolutions = Object.values(TimeResolution);

export type TimeResolutionSectionProps = {
  currentTimeResolution?: TimeResolution;
  onCurrentTimeResolutionChange: (timeResolution: TimeResolution) => void;
};

export const TimeResolutionSection = ({
  currentTimeResolution,
  onCurrentTimeResolutionChange,
}: TimeResolutionSectionProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");

  const onTimeResolutionChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    onCurrentTimeResolutionChange(value as TimeResolution);
  };

  return (
    <Box sx={{ p: "13px 20px" }}>
      <PlaygroundFilterSectionTitle title={t("filter.timeResolutionSection.title")} />
      <RadioGroup value={currentTimeResolution} onChange={onTimeResolutionChange}>
        {timeResolutions.map((timeResolution, index) => (
          <Box
            key={timeResolution}
            sx={{
              ...(index < timeResolutions.length - 1 && { mb: 1 }),
            }}
          >
            <RadioField
              label={t("filter.timeResolutionSection.options", { context: timeResolution })}
              value={timeResolution}
              selected={currentTimeResolution === timeResolution}
            />
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};
