import { FormControlLabelProps } from "@mui/material";
import { Theme, createTheme } from "@mui/material/styles";

import { SupportedLanguage } from "@/i18n";

import { LightTheme } from "./themes/LightTheme";

const themeMap: Record<string, (...args: object[]) => Theme> = {
  LightTheme,
};

export function themeCreator(theme: string, language: SupportedLanguage): Theme {
  const createdTheme = themeMap[theme];
  const themeArgs: object[] = [
    ...(language.dataGridLocalization ? [language.dataGridLocalization] : []),
    ...(language.xDatePickersLocale ? [language.xDatePickersLocale] : []),
    ...(language.muiLocalization ? [language.muiLocalization] : []),
  ];

  if (createdTheme) return createdTheme(...themeArgs);
  return createTheme(undefined, themeArgs);
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxl: true;
  }
  interface ThemeOptions {
    themeColors: ThemeColors;
    sidebar: {
      background: string;
      textColor: string;
      selectedTextColor: string;
      selectedBackgroundStartColor: string;
      selectedBackgroundColor: string;
      drawerWidth: number;
      fontFamility: React.CSSProperties["fontFamily"];
      fontSize: React.CSSProperties["fontSize"];
      fontWeight: React.CSSProperties["fontWeight"];
      lineHeight: React.CSSProperties["lineHeight"];
      letterSpacing: React.CSSProperties["letterSpacing"];
    };
  }
  interface Theme {
    themeColors: ThemeColors;
    sidebar: {
      background: string;
      textColor: string;
      selectedTextColor: string;
      selectedBackgroundStartColor: string;
      selectedBackgroundColor: string;
      drawerWidth: number;
      fontFamily: React.CSSProperties["fontFamily"];
      fontSize: React.CSSProperties["fontSize"];
      fontWeight: React.CSSProperties["fontWeight"];
      lineHeight: React.CSSProperties["lineHeight"];
    };
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sidebarItem: true;
    indexCardTitle: true;
    indexCardListItem: true;
    playgroundAnalysisSwitchTitle: true;
    playgroundAnalysisSwitchTitleLink: true;
    pageSectionTitle: true;
    menuTitle: true;
    pageSubSectionTitle: true;
    headerMenuTitle: true;
    headerMenuSubTitle: true;
    headerMenuSectionTitle: true;
    headerMenuSectionText: true;
    uploadDropFieldError: true;
    uploadDropFieldDescription1: true;
    uploadDropFieldDescription2: true;
    uploadDropFieldSupportedFileTypes: true;
    uploadFileTableHeader: true;
    uploadFileName: true;
    welcome: true;
    welcomeFooter: true;
    loginHeader: true;
    loginRegistrationDescription: true;
    loginFieldLabel: true;
    welcomeOr: true;
    uploadFileStatus: true;
    tableValue: true;
    tableSubValue: true;
  }
}

declare module "@mui/material/Link" {
  interface LinkPropsVariantOverrides {
    welcomeFooter: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    sidebarItem: React.CSSProperties;
    indexCardTitle: React.CSSProperties;
    indexCardListItem: React.CSSProperties;
    playgroundAnalysisSwitchTitle: React.CSSProperties;
    playgroundAnalysisSwitchTitleLink: React.CSSProperties;
    pageSectionTitle: React.CSSProperties;
    menuTitle: React.CSSProperties;
    pageSubSectionTitle: React.CSSProperties;
    headerMenuTitle: React.CSSProperties;
    headerMenuSubTitle: React.CSSProperties;
    headerMenuSectionTitle: React.CSSProperties;
    headerMenuSectionText: React.CSSProperties;
    uploadDropFieldError: React.CSSProperties;
    uploadDropFieldDescription1: React.CSSProperties;
    uploadDropFieldDescription2: React.CSSProperties;
    uploadDropFieldSupportedFileTypes: React.CSSProperties;
    uploadFileTableHeader: React.CSSProperties;
    uploadFileName: React.CSSProperties;
    welcome: React.CSSProperties;
    welcomeFooter: React.CSSProperties;
    loginHeader: React.CSSProperties;
    loginRegistrationDescription: React.CSSProperties;
    loginFieldLabel: React.CSSProperties;
    welcomeOr: React.CSSProperties;
    uploadFileStatus: React.CSSProperties;
    tableValue: React.CSSProperties;
    tableSubValue: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sidebarItem?: React.CSSProperties;
    indexCardTitle: React.CSSProperties;
    indexCardListItem: React.CSSProperties;
    playgroundAnalysisSwitchTitle: React.CSSProperties;
    playgroundAnalysisSwitchTitleLink: React.CSSProperties;
    pageSectionTitle: React.CSSProperties;
    menuTitle: React.CSSProperties;
    pageSubSectionTitle: React.CSSProperties;
    headerMenuTitle: React.CSSProperties;
    headerMenuSubTitle: React.CSSProperties;
    headerMenuSectionTitle: React.CSSProperties;
    headerMenuSectionText: React.CSSProperties;
    uploadDropFieldError: React.CSSProperties;
    uploadDropFieldDescription1: React.CSSProperties;
    uploadDropFieldDescription2: React.CSSProperties;
    uploadDropFieldSupportedFileTypes: React.CSSProperties;
    uploadFileTableHeader: React.CSSProperties;
    uploadFileName: React.CSSProperties;
    welcome: React.CSSProperties;
    welcomeFooter: React.CSSProperties;
    loginHeader: React.CSSProperties;
    loginRegistrationDescription: React.CSSProperties;
    loginFieldLabel: React.CSSProperties;
    welcomeOr: React.CSSProperties;
    uploadFileStatus: React.CSSProperties;
    tableValue: React.CSSProperties;
    tableSubValue: React.CSSProperties;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    cardObjectTile: true;
    pageSection: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    inactive: true;
    invited: true;
  }

  interface ChipPropsVariantOverrides {
    playgroundFilterChip: true;
  }
}

export type ThemeColors = {
  primaryLight: string;
  primaryMain: string;
  SprengnetterWebBlau100: string;
  SprengnetterWebBlau300: string;
  SprengnetterSchwarzgrau900: string;
};

declare module "@mui/material/styles" {
  interface Palette {
    invited: Palette["primary"];
    uploadFildIndexSelection: Palette["primary"];
  }
  interface PaletteOptions {
    invited?: Palette["primary"];
    uploadFildIndexSelection?: Palette["primary"];
  }

  interface Components {
    CheckboxField?: {
      defaultProps?: Partial<FormControlLabelProps>;
      styleOverrides?: {
        root?: (params: { ownerState: { checked: boolean }; theme: Theme }) => React.CSSProperties;
      };
    };
    RadioField?: {
      defaultProps?: Partial<FormControlLabelProps>;
      styleOverrides?: {
        root?: (params: { ownerState: { selected: boolean }; theme: Theme }) => React.CSSProperties;
      };
    };
  }
}
