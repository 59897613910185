import { TagType } from "@/api/enums";

import { sprengConnectApi } from "../sprengConnectApi";
import {
  PingResponse,
} from "../types";

export const homeController = sprengConnectApi.injectEndpoints({
  endpoints: (builder) => ({
    home_ping: builder.query<
      PingResponse | undefined,
      void
    >({
      query: () => ({
        url: "ping",
        method: "GET"
      }),
      providesTags: () => [TagType.SprengConnectApi_PingResult]
    }),
  }),
});

export const {
  useHome_pingQuery,
} = homeController;
