import { Box } from "@mui/material";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxField } from "@/components";
import { ObjectState } from "@/types";

import { PlaygroundFilterSectionTitle } from "./PlaygroundFilterSectionTitle";

const objectStateOptions = Object.values(ObjectState);

export type ObjectStateSectionProps = {
  selectedObjectStates?: ObjectState[];
  onSelectedObjectStatesChange: (selectedObjectStates: ObjectState[]) => void;
};

export const ObjectStateSection = ({
  selectedObjectStates,
  onSelectedObjectStatesChange,
}: ObjectStateSectionProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");
  const onObjectStateChange =
    (changedObjectType: ObjectState) =>
    (_event: SyntheticEvent<Element, Event>, checked: boolean): void => {
      if (checked) {
        onSelectedObjectStatesChange([...(selectedObjectStates ?? []), changedObjectType]);
      } else {
        onSelectedObjectStatesChange(
          (selectedObjectStates ?? []).filter((objectState) => objectState !== changedObjectType),
        );
      }
    };

  return (
    <Box sx={{ p: "13px 20px" }}>
      <PlaygroundFilterSectionTitle title={t("filter.objectStateSection.title")} />
      {objectStateOptions.map((objectState, index) => (
        <CheckboxField
          key={objectState}
          checked={selectedObjectStates != null && selectedObjectStates.includes(objectState)}
          label={t("filter.objectStateSection.options", { context: objectState })}
          name="objectTypeGroup"
          onChange={onObjectStateChange(objectState)}
          sx={() => ({
            ...(index < objectStateOptions.length - 1 && { mb: 1 }),
          })}
        />
      ))}
    </Box>
  );
};
