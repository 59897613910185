import { SvgIcon, SvgIconProps } from "@mui/material";

export const CloseIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon sx={{ width: "16px", height: "17px" }} {...props}>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2594_5145)">
        <g clipPath="url(#clip1_2594_5145)">
          <g clipPath="url(#clip2_2594_5145)">
            <g clipPath="url(#clip3_2594_5145)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.95098 0.834735C1.50466 0.388422 0.781049 0.388422 0.334735 0.834735C-0.111578 1.28105 -0.111578 2.00466 0.334735 2.45098L6.38376 8.5L0.334735 14.549C-0.111578 14.9953 -0.111578 15.719 0.334735 16.1653C0.781049 16.6115 1.50466 16.6115 1.95098 16.1653L8 10.1162L14.049 16.1653C14.4953 16.6115 15.219 16.6115 15.6653 16.1653C16.1115 15.719 16.1115 14.9953 15.6653 14.549L9.61624 8.5L15.6653 2.45098C16.1115 2.00466 16.1115 1.28105 15.6653 0.834735C15.219 0.388422 14.4953 0.388422 14.049 0.834735L8 6.88376L1.95098 0.834735Z"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2594_5145">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="clip1_2594_5145">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="clip2_2594_5145">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="clip3_2594_5145">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
