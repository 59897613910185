import { Box, Collapse, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RadioField } from "@/components";
import { LocationResolutionFilterData, LocationResolutionFilterMetadata } from "@/types/playground";

import { LocationTypeGroupFilterOptionMetadata } from "./locationTypeGroupFilterOptionMetadata";
import { LocationTypeGroupFilterOptionType } from "./types";

export type LocationTypeGroupFilterOptionProps = {
  locationTypeGroupFilterOption: LocationTypeGroupFilterOptionType;
  locationResolution: LocationResolutionFilterData;
  onLocationResolutionMetadataChange: (metadata: LocationResolutionFilterMetadata) => void;
};

export const LocationTypeGroupFilterOption = ({
  locationTypeGroupFilterOption,
  locationResolution,
  onLocationResolutionMetadataChange,
}: LocationTypeGroupFilterOptionProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");
  const isSelectedOption = locationTypeGroupFilterOption.type === locationResolution.type;

  return (
    <>
      <Tooltip title={locationTypeGroupFilterOption.inFutureAvailable ? t("filter.inFutureAvailable") : null}>
        <Box>
          <RadioField
            key={locationTypeGroupFilterOption.type}
            label={t(locationTypeGroupFilterOption.label)}
            value={locationTypeGroupFilterOption.type}
            selected={isSelectedOption}
            disabled={locationTypeGroupFilterOption.inFutureAvailable}
          />
        </Box>
      </Tooltip>
      <Collapse in={isSelectedOption}>
        <LocationTypeGroupFilterOptionMetadata
          locationResolutionType={locationTypeGroupFilterOption.type}
          locationResolution={locationResolution}
          onLocationResolutionMetadataChange={onLocationResolutionMetadataChange}
        />
      </Collapse>
    </>
  );
};
