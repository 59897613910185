import { RouteObject } from "react-router-dom";

import { handleDynamicImportError } from "@/helpers/errorHelpers";
import { ErrorPage } from "@/pages";
import { LazyFunctionReturnType, RouteType } from "@/types";

export const routes: RouteObject[] = [
  {
    path: "/",
    lazy: async (): Promise<LazyFunctionReturnType> => {
      try {
        const AppBaseLayout = (await import("../components")).AppBaseLayout;
        return {
          element: <AppBaseLayout />,
          handle: {
            type: RouteType.AppBaseLayout,
            publicRoute: true,
          },
        };
      } catch (error) {
        handleDynamicImportError(error);

        return {
          element: <ErrorPage />,
        };
      }
    },
    children: [
      {
        index: true,
        lazy: async (): Promise<LazyFunctionReturnType> => {
          try {
            const CurrentPage = (await import("../pages")).CurrentPage;
            return {
              element: <CurrentPage />,
              handle: {
                type: RouteType.CurrentPage,
              },
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
      },
      {
        path: "test",
        lazy: async (): Promise<LazyFunctionReturnType> => {
          const TestPage = (await import("../pages")).TestPage;
          return {
            element: <TestPage />,
            handle: {
              type: RouteType.NotFound,
            },
          };
        },
      },
      {
        path: "*",
        lazy: async (): Promise<LazyFunctionReturnType> => {
          const NotFound = (await import("../pages")).NotFoundPage;
          return {
            element: <NotFound />,
            handle: {
              type: RouteType.NotFound,
            },
          };
        },
      },
    ],
  },
];
