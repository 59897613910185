import de from "./de.json";
import en from "./en.json";
import { SupportedLanguagesCode, addResourceBundles } from "@/i18n";

export const namespace = "UserMenu";

declare module "i18next" {
  interface CustomTypeResources {
    [namespace]: typeof de;
  }
}

addResourceBundles([
  { languageCode: SupportedLanguagesCode.de, namespace, resources: de },
  { languageCode: SupportedLanguagesCode.en, namespace, resources: en },
]);
