import { PortfolioAnalysisPageFilter, PortfolioAnalysisUploadedFileInfo as PortfolioAnalysisData, TimeRange } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const defaultFilter: PortfolioAnalysisPageFilter = {
  selectedIndexes: [],
  selectedTimeRanges: [TimeRange.OneYear, TimeRange.ThreeYears],
};

// Define a type for the slice state
export interface PortfolioAnalysisPageSliceState {
  filter: PortfolioAnalysisPageFilter;
  analysisData?: PortfolioAnalysisData;
}

// Define the initial state using that type
const initialState: PortfolioAnalysisPageSliceState = {
  filter: defaultFilter,
  analysisData: undefined,
};

// Implement the slice
export const portfolioAnalysisPageSlice = createSlice({
  name: "portfolioAnalysisPage",
  // 'createSlice' will infer the state type from the 'initialState' argument
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PortfolioAnalysisPageFilter>) => {
      state.filter = action.payload;
    },
    setAnalysisData: (state, action: PayloadAction<PortfolioAnalysisData>) => {
      state.analysisData = action.payload;
    }
  }
});

export const { setFilter, setAnalysisData, } = portfolioAnalysisPageSlice.actions;

export const PortfolioAnalysisPageReducer = portfolioAnalysisPageSlice.reducer;
