import { setCurrentPage } from "@/pages/applicationMainSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { PageType } from "@/types/pageTypes";
import { useCallback, useEffect } from "react";

export const useCurrentPage = (): [PageType, (page: PageType) => void] => {
  const currentPage = useAppSelector((state) => state.applicationMain.currentPage);
  const dispatch = useAppDispatch();

  const changePage = useCallback((page: PageType): void => {
    if (page === currentPage) return;
    dispatch(setCurrentPage(page));
    window.history.pushState({ page }, "", "");
  }, [currentPage, dispatch]);

  return [currentPage, changePage];
};

export const useHandlePageChange = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onpopstate = (event: PopStateEvent): void => {
      if (event.state && event.state.page) {
        dispatch(setCurrentPage(event.state.page));
      }
      else {
        dispatch(setCurrentPage(PageType.LandingPage));
      }
    };

    window.addEventListener("popstate", onpopstate);
    return (): void => {
      window.removeEventListener("popstate", onpopstate);
    }
  }, [dispatch]);
}
