import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ChevronDownIcon } from "@/assets";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { TimeRange } from "@/types";

import { setFilter } from "../portfolioAnalysisPageSlice";

const timeRanges = Object.values(TimeRange);

export const TimeRangeButton = (): JSX.Element => {
  const { t } = useTranslation("PortfolioAnalysisPage");
  const dispatch = useAppDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);

  const filter = useAppSelector((state) => state.portfolioAnalysisPage.filter);
  const { selectedTimeRanges } = filter;

  const handleChoseTimeRangeClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setMenuAnchorEl(null);
  };

  const handleTimeRangeMenuItemClick = (timeRange: TimeRange, isSelected: boolean) => (): void => {
    dispatch(
      setFilter({
        ...filter,
        selectedTimeRanges: isSelected
          ? selectedTimeRanges.filter((selectedTimeRange) => selectedTimeRange !== timeRange)
          : [...selectedTimeRanges, timeRange],
      }),
    );
  };

  return (
    <>
      <Button variant="contained" color="secondary" endIcon={<ChevronDownIcon />} onClick={handleChoseTimeRangeClick}>
        {t("detailView.filter.timeRange.selectedTimeRanges", { count: selectedTimeRanges.length })}
      </Button>
      <Menu anchorEl={menuAnchorEl} open={open} onClose={handleClose}>
        {timeRanges.map((timeRange) => {
          const isSelected = selectedTimeRanges.some((selectedTimeRange) => selectedTimeRange === timeRange);
          return (
            <MenuItem
              key={timeRange}
              onClick={handleTimeRangeMenuItemClick(timeRange, isSelected)}
              selected={isSelected}
            >
              {t("detailView.filter.timeRange.options", { context: timeRange })}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
