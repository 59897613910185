import { IndexType, IndexTypeIdentifier } from "@/types";

export const availableIndexes: IndexType[] = [
  {
    id: 0,
    title: "Immobilienmarkt-Monitoring (S-IM)",
    infoText: "Das Sprengnetter S-IM ermöglicht eine detaillierte Beobachtung der Marktwertentwicklung von Wohnimmobilien in Deutschland über einen Zeitraum von mehr als 35 Jahren. Für 875 Regionen werden Marktdaten analysiert und mittels statistischer Hochrechnungen flächendeckend erfasst. Es bietet präzise Markteinblicke zur kleinräumigen Marktbeobachtung, Risikoerkennung und Wertfortschreibung großer Immobilienportfolios.",
    objectTypesLabel: "ETW / EFH / MFH",
    geographicOutputLevelLabel: "Postleitzahl + Deutschland",
    lag: "3 Quartale, 2 Quartale",
    methodLabel: "Objektbewertung basierend auf SprengnetterONE",
    history: "1987",
    dataBasisLabel: "Kaufpreise, Bodenwerte, Mieten, Baukosten",
    type: "Index (Kaufpreise)",
    updates: "jährlich, halbjährlich",
    identifier: IndexTypeIdentifier.SIM,
  },
  {
    id: 1,
    title: "Immobilien-PreisIndex (IPI)",
    infoText: "Der Sprengnetter Immobilien-Preisindex (IPI) ist ein nahezu Echtzeit-Index mit monatlicher Aktualisierungsrate. Basierend auf mehreren Millionen Kauf- und Angebotspreisen und den fortschrittlichen Regressionsmodellen der AVM Technologie ist er in der Lage, kurzfristige Markentwicklungen zuverlässig abzubilden und kann sowohl für Kauf- und Mietpreise mit einer Historie seit 2012 zur Verfügung gestellt werden.",
    objectTypesLabel: "ETW / EFH / MFH",
    geographicOutputLevelLabel: "Postleitzahl + Deutschland",
    lag: "1 Monat",
    methodLabel: "Regressionsmodel",
    history: "Mitte 2012",
    dataBasisLabel: "Kauf- / Angebotspreise",
    type: "Index (Kaufpreise / Miete)",
    updates: "monatlich",
    identifier: IndexTypeIdentifier.IPI,
  },
  {
    id: 2,
    title: "Energieeffizienz-PreisIndex (S-EPI)",
    infoText: "Der Sprengnetter S-EPI gibt aufschlussreiche Einblicke in die Marktpreisentwicklung von Immobilien, unterschieden nach ihrer Energieeffizienzklasse und erlaubt eine differenzierte Betrachtung der Preisdynamik zwischen renovierten, modernisierten und unrenovierten Immobilien. Eingeteilt in drei EEK-Gruppen (A+-B, C-E, F-H) kann die Entwicklung energieeffizienter High-Performer-Objekte oder Stranded Assets im Verhältnis zu den allgemeinen Marktpreisen untersucht werden.",
    objectTypesLabel: "ETW / EFH / MFH",
    geographicOutputLevelLabel: "Postleizahl + Deutschland",
    lag: "1 Monat",
    methodLabel: "Regressionsmodel",
    history: "2015",
    dataBasisLabel: "Kauf- / Angebotspreise",
    type: "Index (Kaufpreise)",
    updates: "quartalsweise",
    identifier: IndexTypeIdentifier.SEPI,
  },
  {
    id: 3,
    title: "Immobilien-PreisNavigator (S-IPN)",
    infoText: "Der Sprengnetter Immobilien PreisNavigator liefert deutschlandweit präzise Einblicke in die aktuelle Immobilienpreisentwicklung. Mit der AVM Technologie werden für Adressen in ganz Deutschland standardisierte Eigentumswohnungen und Einfamilienhäuser bewertet – differenziert nach Neubauten und Bestandsimmobilien. Die ermittelten Werte werden je Kategorie und Ort gemittelt und auf PLZ-Ebene bereitgestellt und ermöglichen einen schnellen, zuverlässigen Überblick über die aktuellen Marktpreise vor Ort.",
    objectTypesLabel: "ETW / EFH",
    geographicOutputLevelLabel: "Postleizahl + Deutschland",
    lag: "1 Monat",
    methodLabel: "AVM Zeitreihe / Adresssenliste / Aggregation",
    history: "Mitte 2012",
    dataBasisLabel: "Kauf- / Angebotspreise",
    type: "Atlaswert (Kaufpreise / Miete)",
    updates: "quartalsweise",
    identifier: IndexTypeIdentifier.IPN,
  }
];
