import { SvgIcon, SvgIconProps } from "@mui/material";

export const InfoIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    sx={{ width: "24px", height: "24px" }}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2377_4297)">
      <path
        d="M14.25 15.75C14.6642 15.75 15 16.0858 15 16.5C15 16.9142 14.6642 17.25 14.25 17.25H13.5C12.2574 17.25 11.25 16.2426 11.25 15V11.25H10.5C10.0858 11.25 9.75 10.9142 9.75 10.5C9.75 10.0858 10.0858 9.75 10.5 9.75H11.25C12.0784 9.75 12.75 10.4216 12.75 11.25V15C12.75 15.4142 13.0858 15.75 13.5 15.75H14.25ZM11.625 6C12.2463 6 12.75 6.50368 12.75 7.125C12.75 7.74632 12.2463 8.25 11.625 8.25C11.0037 8.25 10.5 7.74632 10.5 7.125C10.5 6.50383 11.0039 6.00024 11.625 6ZM12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
        fill="#005CA9"
      />
    </g>
    <defs>
      <clipPath id="clip0_2377_4297">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
