import { IndexType } from "../IndexType";

export enum TimeRange {
  OneYear = "OneYear",
  ThreeYears = "ThreeYears",
}

export type PortfolioAnalysisPageFilter = {
  selectedIndexes: IndexType[];
  selectedTimeRanges: TimeRange[];
}
