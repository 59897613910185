import { Suspense, lazy } from "react";

import { useCurrentPage, useHandlePageChange } from "@/hooks";
import { PageType } from "@/types/pageTypes";

import { CurrentPageFallback } from "./CurrentPageFallback";

const LandingPage = lazy(() => import("@/pages/landingPage").then((module) => ({ default: module.LandingPage })));
const PlaygroundPage = lazy(() =>
  import("@/pages/playgroundPage").then((module) => ({ default: module.PlaygroundPage })),
);
const PortfolioAnalysisPage = lazy(() =>
  import("@/pages/portfolioAnalysisPage").then((module) => ({ default: module.PortfolioAnalysisPage })),
);
const LoginPage = lazy(() => import("@/pages/loginPage").then((module) => ({ default: module.LoginPage })));

export const CurrentPage = (): JSX.Element => {
  const [currentPage] = useCurrentPage();
  useHandlePageChange();

  const renderPage = (page: PageType): JSX.Element => {
    switch (page) {
      case PageType.LandingPage:
        return <LandingPage />;
      case PageType.Playground:
        return <PlaygroundPage />;
      case PageType.PortfolioAnalysis:
        return <PortfolioAnalysisPage />;
      case PageType.Login:
        return <LoginPage />;
      default:
        return <LoginPage />;
    }
  };

  return <Suspense fallback={<CurrentPageFallback />}>{renderPage(currentPage)}</Suspense>;
};
