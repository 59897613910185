import { createApi } from "@reduxjs/toolkit/query/react";
import { TagType } from "../enums";
import { companyApiBaseQuery } from "./sprengConnectApiBaseQuery";

export const sprengConnectApi = createApi({
  reducerPath: "sprengConnectApi",
  baseQuery: companyApiBaseQuery({
    // Fill in your own server starting URL here
    baseUrl: window.REACT_APP_SPRENGCONNECT_API,
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(TagType),
});
