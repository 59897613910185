export enum IndexTypeIdentifier {
  SIM = "SIM",
  SEPI = "SEPI",
  IPI = "IPI",
  IPN = "IPN",
}

export type IndexType = {
  id: number;
  title: string;
  infoText: string;
  type: string;
  history: string;
  updates: string;
  lag: string;
  dataBasisLabel: string;
  methodLabel: string;
  geographicOutputLevelLabel: string;
  objectTypesLabel: string;
  identifier?: IndexTypeIdentifier;
};
