import { DateTime } from "luxon";

export enum IndexValueChangeType {
  Increase = "Increase",
  Decrease = "Decrease",
}

export type IndexValue = {
  id: number;
  postalCode: string;
  city: string;
  objectType: string;
  indexValue: number;
  indexName: string;
  indexValueDate: DateTime;
  oneYearChange: number;
  oneYearChangeType: IndexValueChangeType;
  threeYearChange: number;
  threeYearChangeType: IndexValueChangeType;
};
