import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon sx={{ width: "18px", height: "18px" }} {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2116_7412)">
        <g clip-path="url(#clip1_2116_7412)">
          <path
            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
            fill="#75BBFF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.2627 4.44953C13.6461 4.67323 13.7754 5.1653 13.5517 5.5486L9.42515 12.6191C9.32071 12.7984 9.17859 12.9537 9.00903 13.0734C8.84079 13.1921 8.64915 13.2736 8.44694 13.3123C8.24166 13.353 8.02993 13.3477 7.82688 13.2969C7.6243 13.2462 7.43541 13.1516 7.27362 13.0196L4.64146 10.9139C4.29491 10.6366 4.23872 10.131 4.51596 9.78442C4.7932 9.43787 5.29888 9.38168 5.64543 9.65892L8.13129 11.6476L12.1637 4.73849C12.3874 4.3552 12.8794 4.22583 13.2627 4.44953Z"
            fill="#005CA9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2116_7412">
          <rect width="18" height="18" fill="white" />
        </clipPath>
        <clipPath id="clip1_2116_7412">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
