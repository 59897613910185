import { CompareType, EnergyEfficiencyGroup, ExpenseType, IndexType, IndexTypeIdentifier, LocationResolutionType, ObjectState, ObjectType, PlaygroundFilter, TimeResolution } from "@/types";

export const isTimeResolutionSectionShown = (index: IndexType): boolean =>
  index.identifier != null && [IndexTypeIdentifier.SIM].includes(index.identifier);

export const isEnergyEfficiencyGroupSectionShown = (index: IndexType): boolean =>
  index.identifier != null && [IndexTypeIdentifier.SEPI].includes(index.identifier);

export const isExpenseTypeSectionShown = (index: IndexType): boolean =>
  index.identifier != null && [IndexTypeIdentifier.IPI, IndexTypeIdentifier.IPN].includes(index.identifier);

export const isCompareTypeSectionShown = (index: IndexType): boolean =>
  index.identifier != null && ![IndexTypeIdentifier.IPN].includes(index.identifier);

export const isObjectStateSectionShown = (index: IndexType): boolean =>
  index.identifier != null && [IndexTypeIdentifier.IPN].includes(index.identifier);

export const getObjectTypeForIndex = (index?: IndexType): ObjectType[] => {
  if (index == null) {
    return [];
  }
  const options: ObjectType[] = [ObjectType.OneFamilyDwelling];
  if (index.identifier !== IndexTypeIdentifier.IPN) {
    options.push(ObjectType.MultiFamilyDwelling);
  }
  options.push(ObjectType.OwnerOccupiedDwelling);
  return options;
};

export const defaultPlaygroundFilter: PlaygroundFilter = {
  locationResolution: {
    metadata: {},
    type: LocationResolutionType.PostalCode
  },
  objectTypes: [ObjectType.OneFamilyDwelling, ObjectType.MultiFamilyDwelling],
  compareType: CompareType.Germany,
  timeResolution: TimeResolution.Yearly,
  energyEfficiencyGroups: [EnergyEfficiencyGroup.High, EnergyEfficiencyGroup.Medium],
  expenseTypes: [ExpenseType.Buy],
  objectStates: [ObjectState.NewBuilding]
};

export const getDefaultFilterForIndex = (index?: IndexType): PlaygroundFilter => {
  if (index == null) {
    return defaultPlaygroundFilter;
  }

  const adjustedFilter: PlaygroundFilter = { ...defaultPlaygroundFilter };
  const availableObjectTypes = getObjectTypeForIndex(index);
  adjustedFilter.objectTypes = adjustedFilter.objectTypes.filter(type => availableObjectTypes.includes(type));

  if (!isTimeResolutionSectionShown(index)) {
    adjustedFilter.timeResolution = undefined;
  }

  if (!isEnergyEfficiencyGroupSectionShown(index)) {
    adjustedFilter.energyEfficiencyGroups = undefined;
  }

  if (!isExpenseTypeSectionShown(index)) {
    adjustedFilter.expenseTypes = undefined;
  }

  if (!isCompareTypeSectionShown(index)) {
    adjustedFilter.compareType = undefined;
  }

  if (!isObjectStateSectionShown(index)) {
    adjustedFilter.objectStates = undefined;
  }

  return adjustedFilter;
}
