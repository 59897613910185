import { SvgIcon, SvgIconProps } from "@mui/material";

export const PlusIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon sx={{ width: "16px", height: "16px" }} {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2589_4641)">
        <g clipPath="url(#clip1_2589_4641)">
          <path
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill="#DEE2E6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.8577 4.57199C8.8577 4.0986 8.47394 3.71484 8.00056 3.71484C7.52718 3.71484 7.14342 4.0986 7.14342 4.57199V7.14342H4.57199C4.0986 7.14342 3.71484 7.52718 3.71484 8.00056C3.71484 8.47394 4.0986 8.8577 4.57199 8.8577H7.14342V11.4291C7.14342 11.9025 7.52718 12.2863 8.00056 12.2863C8.47394 12.2863 8.8577 11.9025 8.8577 11.4291V8.8577H11.4291C11.9025 8.8577 12.2863 8.47394 12.2863 8.00056C12.2863 7.52718 11.9025 7.14342 11.4291 7.14342H8.8577V4.57199Z"
            fill="#6C757C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.8577 4.57199C8.8577 4.0986 8.47394 3.71484 8.00056 3.71484C7.52718 3.71484 7.14342 4.0986 7.14342 4.57199V7.14342H4.57199C4.0986 7.14342 3.71484 7.52718 3.71484 8.00056C3.71484 8.47394 4.0986 8.8577 4.57199 8.8577H7.14342V11.4291C7.14342 11.9025 7.52718 12.2863 8.00056 12.2863C8.47394 12.2863 8.8577 11.9025 8.8577 11.4291V8.8577H11.4291C11.9025 8.8577 12.2863 8.47394 12.2863 8.00056C12.2863 7.52718 11.9025 7.14342 11.4291 7.14342H8.8577V4.57199Z"
            fill="black"
            fillOpacity="0.2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2589_4641">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_2589_4641">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
