import {
  LocationResolutionFilterData,
  LocationResolutionFilterMetadata,
  LocationResolutionType,
} from "@/types/playground";

import { PostalCodeMetadata } from "./PostalCodeMetadata";

export type LocationTypeGroupFilterOptionMetadataProps = {
  locationResolutionType: LocationResolutionType;
  locationResolution: LocationResolutionFilterData;
  onLocationResolutionMetadataChange: (metadata: LocationResolutionFilterMetadata) => void;
};

export const LocationTypeGroupFilterOptionMetadata = ({
  locationResolutionType,
  locationResolution,
  onLocationResolutionMetadataChange,
}: LocationTypeGroupFilterOptionMetadataProps): JSX.Element | undefined => {
  if (locationResolutionType === LocationResolutionType.PostalCode) {
    return (
      <PostalCodeMetadata
        metadata={locationResolution.metadata}
        onLocationResolutionMetadataChange={onLocationResolutionMetadataChange}
      />
    );
  }
};
