import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./windowConfiguration.ts";

import "@/i18n/initI18n.ts";
import { LocalizationProvider } from "@/providers";
import { store } from "@/store/store.ts";
import { ThemeProviderWrapper } from "@/theme/ThemeProvider.tsx";

import { routes } from "./routes/routes";

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProviderWrapper>
        <LocalizationProvider>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProviderWrapper>
    </Provider>
  </React.StrictMode>,
);
