import { SvgIcon, SvgIconProps } from "@mui/material";

export const DocumentsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon sx={{ width: "16px", height: "17px" }} {...props}>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2518_417)">
        <g clipPath="url(#clip1_2518_417)">
          <path
            d="M12 2.5C12 1.96957 12.2107 1.46086 12.5858 1.08579C12.9609 0.710713 13.4696 0.5 14 0.5H4.85714C4.32671 0.5 3.818 0.710713 3.44293 1.08579C3.06785 1.46086 2.85714 1.96957 2.85714 2.5V13.0714C2.85714 14.0061 2.40838 14.8359 1.71456 15.3571H0.571429C0.255838 15.3571 0 15.613 0 15.9286C0 16.2441 0.255838 16.5 0.571429 16.5H9.14286C9.90062 16.5 10.6273 16.199 11.1632 15.6632C11.699 15.1273 12 14.4006 12 13.6429V2.5Z"
            fill="#75BBFF"
          />
          <path
            d="M14 0.5C14.5304 0.5 15.0391 0.710713 15.4142 1.08579C15.7893 1.46086 16 1.96957 16 2.5V4.5C16 4.65155 15.9398 4.7969 15.8327 4.90406C15.7255 5.01122 15.5801 5.07143 15.4286 5.07143H12V2.5C12 1.96957 12.2107 1.46086 12.5858 1.08579C12.9609 0.710713 13.4696 0.5 14 0.5Z"
            fill="#005CA9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.91754 4.49944C5.91754 4.10495 6.23735 3.78516 6.63183 3.78516H8.84601C9.2405 3.78516 9.5603 4.10495 9.5603 4.49944C9.5603 4.89393 9.2405 5.21373 8.84601 5.21373H6.63183C6.23735 5.21373 5.91754 4.89393 5.91754 4.49944ZM4.44141 7.92801C4.44141 7.53352 4.7612 7.21373 5.15569 7.21373H8.84599C9.24048 7.21373 9.56027 7.53352 9.56027 7.92801C9.56027 8.32251 9.24048 8.6423 8.84599 8.6423H5.15569C4.7612 8.6423 4.44141 8.32251 4.44141 7.92801ZM5.15569 10.6423C4.7612 10.6423 4.44141 10.9621 4.44141 11.3566C4.44141 11.7511 4.7612 12.0709 5.15569 12.0709H8.84599C9.24048 12.0709 9.56027 11.7511 9.56027 11.3566C9.56027 10.9621 9.24048 10.6423 8.84599 10.6423H5.15569Z"
            fill="#005CA9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2518_417">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="clip1_2518_417">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
