export enum TimeResolution {
  Yearly = "Yearly",
  SemiAnnually = "SemiAnnually",
}

export enum ObjectType {
  OneFamilyDwelling = "OneFamilyDwelling",
  MultiFamilyDwelling = "MultiFamilyDwelling",
  OwnerOccupiedDwelling = "OwnerOccupiedDwelling",
}

export enum ObjectState {
  NewBuilding = "NewBuilding",
  ExistingBuilding = "ExistingBuilding",
}

export enum LocationResolutionType {
  PostalCode = "PostalCode",
  Community = "Community",
  County = "County",
  FederalState = "FederalState",
}

export enum EnergyEfficiencyGroup {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export enum ExpenseType {
  Buy = "Buy",
  Rent = "Rent",
}

export enum CompareType {
  Germany = "Germany",
}

export type LocationResolutionFilterMetadata = {
  postalCodes?: string[];
}

export type LocationResolutionFilterData = {
  type: LocationResolutionType;
  metadata: LocationResolutionFilterMetadata;
}

export type PlaygroundFilter = {
  objectTypes: ObjectType[];
  locationResolution: LocationResolutionFilterData;
  compareType?: CompareType;
  timeResolution?: TimeResolution;
  energyEfficiencyGroups?: EnergyEfficiencyGroup[];
  expenseTypes?: ExpenseType[];
  objectStates?: ObjectState[];
}
