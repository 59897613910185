import { Box, Chip, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CloseIcon, PlusIcon } from "@/assets";
import { LocationResolutionFilterMetadata } from "@/types/playground";

export type PostalCodeMetadataProps = {
  metadata: LocationResolutionFilterMetadata;
  onLocationResolutionMetadataChange: (metadata: LocationResolutionFilterMetadata) => void;
};

export const PostalCodeMetadata = ({
  metadata,
  onLocationResolutionMetadataChange,
}: PostalCodeMetadataProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");

  const [postalCode, setPostalCode] = useState<string>("");

  const onEnterValue = (): void => {
    if (!postalCode) {
      return;
    }

    const regexPostalCode = /\b\d{4,5}\b/g;

    if (!regexPostalCode.test(postalCode)) {
      return;
    }

    const matches = postalCode.match(regexPostalCode);

    if (!matches) {
      return;
    }

    const uniqueMatches = Array.from(new Set(matches));

    onLocationResolutionMetadataChange({
      postalCodes: Array.from(new Set([...(metadata.postalCodes ?? []), ...uniqueMatches])),
    });
  };

  const onDeletePostalCode = (code: string) => (): void => {
    onLocationResolutionMetadataChange({
      postalCodes: metadata.postalCodes?.filter((c) => c !== code),
    });
  };

  return (
    <Box sx={{ p: "15px 0px 20px 44px" }}>
      <TextField
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        fullWidth
        variant="outlined"
        helperText={t("filter.locationResolutionGroup.enterPostalCodeInfo")}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnterValue();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={onEnterValue} disabled={!postalCode}>
                <PlusIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {metadata.postalCodes && metadata.postalCodes.length > 0 && (
        <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          {metadata.postalCodes.map((code) => (
            <Chip
              key={code}
              color="primary"
              label={code}
              variant="playgroundFilterChip"
              onDelete={onDeletePostalCode(code)}
              deleteIcon={<CloseIcon />}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
