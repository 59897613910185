import { Box, Typography } from "@mui/material";

export type PlaygroundFilterSectionTitleProps = {
  title: string;
};

export const PlaygroundFilterSectionTitle = ({ title }: PlaygroundFilterSectionTitleProps): JSX.Element => {
  return (
    <Box sx={{ margin: "12px 13px" }}>
      <Typography variant="pageSubSectionTitle">{title}</Typography>
    </Box>
  );
};
