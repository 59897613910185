import { Box, Button, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";

export type ConfirmDialogProps = {
  open: boolean;
  title: string;
  message: string;
  cancelText?: string;
  confirmText: string;
  onDecision: (decision: boolean) => void;
};

export const ConfirmDialog = ({
  onDecision,
  open,
  cancelText,
  confirmText,
  message,
  title,
}: ConfirmDialogProps): JSX.Element => {
  const handleDecisionClick = (decision: boolean) => (): void => {
    onDecision(decision);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" scroll="paper" disableRestoreFocus>
      <DialogTitle
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          p: "15.5px 20px ",
        })}
      >
        {title}
      </DialogTitle>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 1 }}>
        <DialogContentText>{message}</DialogContentText>
      </Box>
      <DialogActions>
        {cancelText != null && (
          <Button autoFocus variant="contained" color="secondary" onClick={handleDecisionClick(false)}>
            {cancelText}
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={handleDecisionClick(true)}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
