import { Box } from "@mui/material";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxField } from "@/components";
import { EnergyEfficiencyGroup } from "@/types/playground";

import { PlaygroundFilterSectionTitle } from "./PlaygroundFilterSectionTitle";

const energyEfficiencyGroups = Object.values(EnergyEfficiencyGroup);

export type EnergyEfficiencyGroupsProps = {
  currentEnergyEfficiencyGroups?: EnergyEfficiencyGroup[];
  onCurrentEnergyEfficiencyGroupsChange: (energyEfficiencyGroups: EnergyEfficiencyGroup[]) => void;
};

export const EnergyEfficiencyGroupsSection = ({
  currentEnergyEfficiencyGroups,
  onCurrentEnergyEfficiencyGroupsChange,
}: EnergyEfficiencyGroupsProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");

  const onEnergyEfficiencyGroupsChange =
    (energyEfficiencyGroup: EnergyEfficiencyGroup) =>
    (_event: SyntheticEvent<Element, Event>, checked: boolean): void => {
      onCurrentEnergyEfficiencyGroupsChange(
        checked
          ? [...(currentEnergyEfficiencyGroups ?? []), energyEfficiencyGroup]
          : (currentEnergyEfficiencyGroups ?? []).filter((group) => group !== energyEfficiencyGroup),
      );
    };

  return (
    <Box sx={{ p: "13px 20px" }}>
      <PlaygroundFilterSectionTitle title={t("filter.energyEfficiencySection.title")} />
      {energyEfficiencyGroups.map((energyEfficiencyGroup, index) => (
        <CheckboxField
          key={energyEfficiencyGroup}
          checked={
            currentEnergyEfficiencyGroups != null && currentEnergyEfficiencyGroups.includes(energyEfficiencyGroup)
          }
          label={t("filter.energyEfficiencySection.options", { context: energyEfficiencyGroup })}
          name="energyEfficiencyGroup"
          onChange={onEnergyEfficiencyGroupsChange(energyEfficiencyGroup)}
          sx={() => ({
            ...(index < energyEfficiencyGroups.length - 1 && { mb: 1 }),
          })}
        />
      ))}
    </Box>
  );
};
