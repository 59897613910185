import { Box } from "@mui/material";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxField } from "@/components";
import { ExpenseType } from "@/types/playground";

import { PlaygroundFilterSectionTitle } from "./PlaygroundFilterSectionTitle";

const expenseTypes = Object.values(ExpenseType);

export type ExpenseTypeSectionProps = {
  currentExpenseTypes?: ExpenseType[];
  onCurrentExpenseTypesChange: (expenseTypes: ExpenseType[]) => void;
};

export const ExpenseTypeSection = ({
  currentExpenseTypes,
  onCurrentExpenseTypesChange,
}: ExpenseTypeSectionProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");

  const onEnergyEfficiencyGroupsChange =
    (expenseType: ExpenseType) =>
    (_event: SyntheticEvent<Element, Event>, checked: boolean): void => {
      onCurrentExpenseTypesChange(
        checked
          ? [...(currentExpenseTypes ?? []), expenseType]
          : (currentExpenseTypes ?? []).filter((indexType) => indexType !== expenseType),
      );
    };

  return (
    <Box sx={{ p: "13px 20px" }}>
      <PlaygroundFilterSectionTitle title={t("filter.expenseTypeSection.title")} />
      {expenseTypes.map((expenseType, index) => (
        <CheckboxField
          key={expenseType}
          checked={currentExpenseTypes != null && currentExpenseTypes.includes(expenseType)}
          label={t("filter.expenseTypeSection.options", { context: expenseType })}
          name="expenseType"
          onChange={onEnergyEfficiencyGroupsChange(expenseType)}
          sx={() => ({
            ...(index < expenseTypes.length - 1 && { mb: 1 }),
          })}
        />
      ))}
    </Box>
  );
};
