import { Box, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  LocationResolutionFilterData,
  LocationResolutionFilterMetadata,
  LocationResolutionType,
} from "@/types/playground";

import { PlaygroundFilterSectionTitle } from "../PlaygroundFilterSectionTitle";
import { LocationTypeGroupFilterOption } from "./LocationTypeGroupFilterOption";
import { LocationTypeGroupFilterOptionType } from "./types";

const locationTypeGroupFilterOptions: LocationTypeGroupFilterOptionType[] = [
  {
    label: "filter.locationResolutionGroup.postalCode",
    type: LocationResolutionType.PostalCode,
    inFutureAvailable: false,
  },
  {
    label: "filter.locationResolutionGroup.community",
    type: LocationResolutionType.Community,
    inFutureAvailable: true,
  },
  {
    label: "filter.locationResolutionGroup.county",
    type: LocationResolutionType.County,
    inFutureAvailable: true,
  },
  {
    label: "filter.locationResolutionGroup.federalState",
    type: LocationResolutionType.FederalState,
    inFutureAvailable: true,
  },
];

export type LocationResolutionSectionProps = {
  locationResolution: LocationResolutionFilterData;
  onLocationResolutionChange: (locationResolutionData: LocationResolutionFilterData) => void;
};

export const LocationResolutionSection = ({
  locationResolution,
  onLocationResolutionChange,
}: LocationResolutionSectionProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");

  const onLocationTypeGroupChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    onLocationResolutionChange({ ...locationResolution, type: value as LocationResolutionType });
  };
  const onLocationResolutionMetadataChange = (metadata: LocationResolutionFilterMetadata): void => {
    onLocationResolutionChange({ ...locationResolution, metadata });
  };

  return (
    <Box sx={{ p: "13px 20px" }}>
      <PlaygroundFilterSectionTitle title={t("filter.locationResolutionGroup.title")} />
      <RadioGroup value={locationResolution.type} onChange={onLocationTypeGroupChange}>
        {locationTypeGroupFilterOptions.map((locationTypeGroupFilterOption, index) => (
          <Box
            key={locationTypeGroupFilterOption.type}
            sx={{
              ...(index < locationTypeGroupFilterOptions.length - 1 && { mb: 1 }),
            }}
          >
            <LocationTypeGroupFilterOption
              locationTypeGroupFilterOption={locationTypeGroupFilterOption}
              locationResolution={locationResolution}
              onLocationResolutionMetadataChange={onLocationResolutionMetadataChange}
            />
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};
