import { SvgIcon, SvgIconProps } from "@mui/material";

export const LoadingIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon sx={{ width: "18px", height: "18px" }} {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2116_7445)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.973636 3.94812L1.66332 4.36193C3.20082 1.93446 5.91121 0.320801 9.00006 0.320801C12.5041 0.320801 15.5211 2.39737 16.8912 5.38285C17.1134 5.86687 16.9011 6.43932 16.4171 6.66148C15.9331 6.88363 15.3606 6.67133 15.1384 6.18732C14.071 3.86144 11.7228 2.24937 9.00006 2.24937C6.61593 2.24937 4.51894 3.48545 3.31756 5.35448L4.18792 5.87669C4.41087 6.01047 4.53129 6.26538 4.49303 6.52256C4.45476 6.77974 4.26533 6.98854 4.01309 7.05161L1.44166 7.69446C1.09722 7.78058 0.748193 7.57115 0.662082 7.22672L0.0192252 4.65529C-0.0438368 4.40303 0.0512612 4.13764 0.260175 3.98285C0.46909 3.82806 0.750678 3.81436 0.973636 3.94812ZM2.86165 11.8114C2.63951 11.3274 2.06704 11.1151 1.58302 11.3373C1.09901 11.5594 0.886726 12.1319 1.10887 12.6159C2.4791 15.6014 5.49607 17.6779 9.00006 17.6779C12.0889 17.6779 14.7993 16.0642 16.3368 13.6368L17.0264 14.0506C17.2494 14.1844 17.531 14.1706 17.7399 14.0158C17.9487 13.861 18.0439 13.5957 17.9809 13.3434L17.338 10.772C17.2519 10.4276 16.9028 10.2182 16.5584 10.3043L13.9869 10.9471C13.7347 11.0102 13.5453 11.219 13.507 11.4762C13.4688 11.7334 13.5891 11.9883 13.8121 12.1221L14.6825 12.6443C13.4811 14.5133 11.3842 15.7494 9.00006 15.7494C6.27736 15.7494 3.92914 14.1373 2.86165 11.8114Z"
          fill="#005CA9"
        />
      </g>
      <defs>
        <clipPath id="clip0_2116_7445">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
